import axios from "axios";
import {backendUrl, getAxiosConfig} from "../config";

export interface SiteMetadata {
    satoPlantNumber: string
}

export interface Site {
    siteCode: string,
    siteName: string,
    startDate: string,
    endDate?: string | null,
    corporationCode: string,
    companyCode: string,
    siteChainCode: string,
    siteChainName: string,
    businessOpeningDate: string,
    businessClosingDate?: string | null,
    siteStreetAddress: string,
    sitePostalCode: string,
    sitePostalOffice: string,
    siteMunicipalityCode?: string | null,
    siteMunicipalityName?: string | null,
    meta: SiteMetadata,
    siteInMarttiUntilDate?: string | null
}

export const siteArrayToMap = (arr: Site[]) => new Map(arr.map(obj => [obj.siteCode, obj]));

export const getSites = (token: string) => {
    return new Promise<Site[]>((resolve, reject) => {
        axios.get(backendUrl + "/sites", getAxiosConfig(token))
            .then(response => {
                if (response.status === 200) {
                    const sites = response.data as Site[]
                    resolve(sites)
                }
            })
            .catch(reason => {
                console.log("Failed to load sites, reason = " + reason)
                reject(reason)
            })
    })
}