import {useTranslation} from "react-i18next";
import {useAccount, useMsal} from "@azure/msal-react";
import {useCallback, useEffect, useState} from "react";
import {appRoles} from "../config";
import {AxiosError} from "axios";
import {useParams} from "react-router-dom";
import {isValidSokIdOrEan, ProductSearchInput} from "./ProductSearchInput";
import {GenericSearchResult} from "../domain/GenericSearchResult";
import {LoadingAnimation} from "./LoadingAnimation";
import {userHasRole} from "../utils/AuthUtil";
import {RetailProductApiSearchErrorMessage} from "./RetailProductApiSearchErrorMessage";
import {
    getAccessToken,
    getAllBySokIdAndEan,
    handleSearchError, 
    searchBySokIdUsingPost,
    searchFromRetailProductApiBySokIdUsingPost,
    SokIdUrlParam
} from "../utils/SearchFormUtil";
import {getSites, Site} from "../utils/SitesApi";
import {ChainRetailPricesTable} from "./ChainRetailPricesTable";
import {StoreRetailPricesTable} from "./StoreRetailPricesTable";
import {StoreRetailCampaignPricesTable} from "./StoreRetailCampaignPricesTable";
import {BonusBuysTable, BonusBuysType} from "./BonusBuysTable";
import {PreviousSearchList} from "./PreviousSearchList";
import {handleSetSearchToLocalStorage} from "../utils/LocalStorageUtil";
import MultipleResults from "./MultipleResults";
import { MultipleSearchResults } from "../domain/MultipleSearchResults";

export const PricesSearchForm = () => {
    const { t } = useTranslation()
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const urlParams = useParams<SokIdUrlParam>()
    const hasExtendedRole = userHasRole(appRoles.ExtendedUser, account)
    const [multipleSearchResults, setMultipleSearchResults] = useState<MultipleSearchResults | null>(null); 
    const [chainRetailPrices, setChainRetailPrices] = useState<GenericSearchResult>({ inProgress: false });
    const [storeRetailPrices, setStoreRetailPrices] = useState<GenericSearchResult>({ inProgress: false });
    const [storeRetailCampaignPrices, setStoreRetailCampaignPrices] = useState<GenericSearchResult>({ inProgress: false });
    const [bonusBuys, setBonusBuys] = useState<GenericSearchResult>({ inProgress: false });
    const [percentageDiscounts, setPercentageDiscounts] = useState<GenericSearchResult>({ inProgress: false });
    const [accessToken, setAccessToken] = useState("");
    const [sites, setSites] = useState<Site[]>([])

    const handleChainPriceSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, res => setChainRetailPrices(res))
    }
    const handleStoreNormalPriceSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, res => setStoreRetailPrices(res))
    }
    const handleStoreCampaignPriceSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, res => setStoreRetailCampaignPrices(res))
    }
    const handleBonusBuysSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, res => setBonusBuys(res))
    }
    const handlePercentageDiscountSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, res => setPercentageDiscounts(res))
    }

    const searchRetailPricesBySokId = useCallback((sokId: string, token: string, ean: string | null) => {
        searchFromRetailProductApiBySokIdUsingPost("chainRetailPrices", sokId, token, "/prices/" + sokId)
            .then(res => setChainRetailPrices(res))
            .catch(handleChainPriceSearchError)
        searchFromRetailProductApiBySokIdUsingPost("storeRetailPrices", sokId, token, "/prices/" + sokId, { conditionTypes: ["VKP0"] })
            .then(res => setStoreRetailPrices(res))
            .catch(handleStoreNormalPriceSearchError)
        searchFromRetailProductApiBySokIdUsingPost("storeRetailPrices", sokId, token, "/prices/" + sokId, { conditionTypes: ["VKA0"] })
            .then(res => setStoreRetailCampaignPrices(res))
            .catch(handleStoreCampaignPriceSearchError)
        searchBySokIdUsingPost("bonusBuys", sokId, token, "/prices/" + sokId)
            .then(res => setBonusBuys(res))
            .catch(handleBonusBuysSearchError)
        searchBySokIdUsingPost("discounts", sokId, token, "/prices/" + sokId)
            .then(res => {
                setPercentageDiscounts(res)
                handleSetSearchToLocalStorage(res, ean, sokId)
            })
            .catch(handlePercentageDiscountSearchError)
    }, [])

    const search = useCallback((searchTerm: string, token: string) => {
        if (!searchTerm || !isValidSokIdOrEan(searchTerm)) {
            return
        }
        setChainRetailPrices({ inProgress: true })
        setStoreRetailPrices({ inProgress: true })
        setBonusBuys({ inProgress: true })
        setPercentageDiscounts({ inProgress: true })
        setMultipleSearchResults(null)

        searchTerm = searchTerm.trim()

        getAllBySokIdAndEan(searchTerm, token, "/prices/")
        .then(result => {
            const numberOfResults = result.getNumberOfResults()
            if (numberOfResults === 0) {
                setChainRetailPrices({ inProgress: false, statusCode: 404 })
                setStoreRetailPrices({ inProgress: false, statusCode: 404 })
                setStoreRetailCampaignPrices({ inProgress: false, statusCode: 404 })
                setBonusBuys({ inProgress: false, statusCode: 404 })
                setPercentageDiscounts({ inProgress: false, statusCode: 404 })
            } else if(numberOfResults === 1){
                const searchResult = result.getOneResultOrNull();
                if (searchResult !== null) {
                    searchRetailPricesBySokId(searchResult.sokId!, token, searchResult.data.ean )
                }
            } else {
                setMultipleSearchResults(result)
                setChainRetailPrices({ inProgress: false })
                setStoreRetailPrices({ inProgress: false })
                setStoreRetailCampaignPrices({ inProgress: false })
                setBonusBuys({ inProgress: false })
                setPercentageDiscounts({ inProgress: false })
            }
        })
        .catch(error => {
            console.error(error)
            handleSearchError(error, res => setChainRetailPrices(res))
            handleSearchError(error, res => setStoreRetailPrices(res))
            handleSearchError(error, res => setBonusBuys(res))
            handleSearchError(error, res => setStoreRetailCampaignPrices(res))
        })
    }, [searchRetailPricesBySokId])

    useEffect(() => {
        getAccessToken(account, instance).then(token => {
            setAccessToken(token)
            getSites(token).then(response => setSites(response))
            if (urlParams.sokId) {
                searchRetailPricesBySokId(urlParams.sokId, token, null)
            }
        })
    }, [instance, account, urlParams, searchRetailPricesBySokId]);

    // RouteGuard makes sure this does not happen in the app, but this is needed for component tests.
    if (!hasExtendedRole) {
        return <p>{t("PermissionDenied.genericMessage")}</p>
    }
    return (
        <div>
            <h1>{t("Prices.title")}</h1>
            <p>{t("Prices.helpText")}</p>
            <PreviousSearchList site={"/prices/"} />
            <ProductSearchInput
                initialValue={urlParams ? urlParams.sokId : ""}
                onSearch={(value) => {
                    search(value, accessToken)
                }}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        const target: any = event.target
                        const text: string = target.value
                        search(text, accessToken)
                    }
                }}
            />

            <br/><br/>
            
            {multipleSearchResults ?
                <MultipleResults results={multipleSearchResults} site={"/prices/"} /> 
                :
                <>
                    <h2>{t("Prices.chainRetailPrices")}</h2>
                    {chainRetailPrices.inProgress && <LoadingAnimation/>}

                    {chainRetailPrices.statusCode && chainRetailPrices.statusCode !== 200 ?
                        <RetailProductApiSearchErrorMessage searchResult={chainRetailPrices}/>
                        :
                        <ChainRetailPricesTable prices={chainRetailPrices.data}/>
                    }
                    <br/>

                    <h2>{t("Prices.storeRetailPrices")}</h2>
                    {storeRetailPrices.inProgress && <LoadingAnimation/>}

                    {storeRetailPrices.statusCode && storeRetailPrices.statusCode !== 200 ?
                        <RetailProductApiSearchErrorMessage searchResult={storeRetailPrices}/>
                        :
                        <StoreRetailPricesTable prices={storeRetailPrices.data} sites={sites}/>
                    }
                    <br/>

                    <h2>{t("Prices.storeRetailCampaignPrices")}</h2>
                    {storeRetailCampaignPrices.inProgress && <LoadingAnimation/>}

                    {storeRetailCampaignPrices.statusCode && storeRetailCampaignPrices.statusCode !== 200 ?
                        <RetailProductApiSearchErrorMessage searchResult={storeRetailCampaignPrices}/>
                        :
                        <StoreRetailCampaignPricesTable prices={storeRetailCampaignPrices.data} sites={sites}/>
                    }
                    <br/>

                    <h2>{t("Prices.bonusBuys")}</h2>
                    {bonusBuys.inProgress && <LoadingAnimation/>}
                    {bonusBuys.statusCode && bonusBuys.statusCode !== 200 ?
                        <RetailProductApiSearchErrorMessage searchResult={bonusBuys}/>
                        :
                        <BonusBuysTable data={bonusBuys.data} sites={sites} type={BonusBuysType.BONUSBUY}/>
                    }
                    <br/>

                    <h2>{t("Prices.percentageDiscounts")}</h2>
                    {percentageDiscounts.inProgress && <LoadingAnimation/>}
                    {percentageDiscounts.statusCode && percentageDiscounts.statusCode !== 200 ?
                        <RetailProductApiSearchErrorMessage searchResult={percentageDiscounts}/>
                        :
                        <BonusBuysTable data={percentageDiscounts.data} sites={sites} type={BonusBuysType.PERCENTAGE}/>
                    }
                </>
            }
        </div>
    );
};